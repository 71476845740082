//Colors
$primary: #2D3142;
$secondary: #D86837;
$light_primary: #4F5D75;
$light: #BFC0C0;
$dark: #333333;

// Break Point
$xs: 475px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;