@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button{border:0;background:none}button:focus{outline:0}footer{position:absolute;bottom:0;width:100%}div:focus{outline:none}a{color:#333;cursor:pointer}a:focus{outline:0}a:hover{color:#333;text-decoration:underline}input:focus,textarea:focus{outline:0}body{font-family:'Raleway', sans-serif;color:#333}@media (max-width: 576px){body{font-size:14px}}.title__content{font-size:24px;font-weight:700}.home{height:100vh;background-color:#2d3142;color:#fff;border-bottom:16px solid #d86837}.home .container{height:100%}.home .home-shadow{font-family:"Nunito", sans-serif;display:flex;flex-direction:column;justify-content:center;padding:4em 0;height:100%}.home .home-shadow .home-title__name{color:#d86837;font-weight:900;font-size:32px}@media (min-width: 768px){.home .home-shadow .home-title__name{font-size:42px}}.home .home-shadow .home-title__occupation{color:#2d3142;text-shadow:-1px -1px 0 #fff,0 -1px 0 #fff,1px -1px 0 #fff,1px 0 0 #fff,1px 1px 0 #fff,0 1px 0 #fff,-1px 1px 0 #fff,-1px 0 0 #fff;font-size:32px;font-family:"Libre Franklin";font-weight:900;margin-bottom:16px}@media (min-width: 768px){.home .home-shadow .home-title__occupation{font-size:72px}}.home-link{display:flex}.home-link a{margin-right:2em}.home-link a img{width:24px;height:24px}@media (min-width: 768px){.home-link a img{width:33px;height:33px}}.home .home-more{position:relative;bottom:0;color:#32374b}.porto{font-family:"Nunito", sans-serif}.porto-header{background-color:#d86837;color:#fff;font-size:24px;padding:12px 0px 24px}.porto-header .subtitle{font-size:14px}.porto-content{padding:2em 0}.porto-content__image{max-width:100%;margin-bottom:24px}.porto-content__separator{border:1px solid #bfc0c0;border-radius:2px;padding:16px}.porto-content__fluid{flex-wrap:wrap;-moz-column-width:25em;-webkit-column-width:25em;-moz-column-gap:0.5em;-webkit-column-gap:0.5em;grid-column-gap:0.5em;column-gap:0.5em;column-width:25em}.porto-content__card{display:inline-block;margin:0.25rem;padding:1rem;width:100%}

